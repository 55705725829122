import { version } from '../package.json'
import Vue from 'vue'
import router from './router'
import App from './App.vue'

const app = new Vue({
  router,
  render: h => h(App)
})

/**
 * App initialization completed
 */
console.debug(`Vue app version ${version} is ready`)

app.$mount('#app')
